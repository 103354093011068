::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
   }
   
   ::-webkit-scrollbar-thumb {
    background: rgba(202, 201, 201, 0.6);
    border-radius: 10px;
    margin: 0px;
    padding: 0px;
   }
   